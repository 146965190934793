<template lang="html">
    <main-layout :settings-breadcrumbs="settingsBreadcrumbs">
        <template #content>
            <profile-form-layout
                :show-modal="showModal"
                :title="'Password'"
                @validate="validate"
                @close-modal="showModal=false"
                @confirm="confirm"
            >
                <template #headerIcon>
                    <lock />
                </template>
                <template #form>
                    <div class="profile-info__form mt-7">
                        <div class="profile-info__form-group">
                            <div class="profile-info__form-row">
                                <div
                                    class="profile-info__col60 profile-info__column profile-info__col100--sm"
                                    style="margin: auto; padding-bottom: 10px; position: relative;"
                                >
                                    <form-input
                                        v-model="$v.password.$model"
                                        name="password"
                                        legend="Password"
                                        prepend-icon="LockIcon"
                                        :field.sync="password"
                                        :append-icon="showPassword ? 'EyeIcon' : 'EyeOffIcon'"
                                        :type="showPassword ? 'text' : 'password'"
                                        :errors="validationMessage($v.password)"
                                        :is-valid="$v.password.$dirty && !$v.password.$anyError"
                                        @clicked="showPassword = !showPassword"
                                        @input.native="$v.password.$touch()"
                                        @blur.native="$v.password.$touch(); passwordInFocus = false"
                                        @focus="passwordInFocus = true"
                                    />
                                    <!--                                    <progress-linear-->
                                    <!--                                        :validate="$v.password.$params.passwordStrengthTest"-->
                                    <!--                                        class="progress-linear"-->
                                    <!--                                    />-->
                                    <div
                                        v-if="passwordInFocus && $v.password.$anyError"
                                        class="password-validation"
                                    >
                                        <password-validator-helper :password="password" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </profile-form-layout>
        </template>
    </main-layout>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/'

import PasswordValidatorHelper from '@/components/PasswordValidatorHelper';
import { validationMixin } from 'vuelidate'
import formValidationMixin from '@/mixins/formValidationMixin'
import passwordValidationMixin from '@/mixins/passwordValidationMixin'
import { validationMessage } from 'vuelidate-messages'
import { validationRules } from '@/validation/reset-password/Rules'
import { formMessages } from '@/validation/reset-password/Messages'

import MainLayout from '@/layouts/MainLayout';
import ProfileFormLayout from '@/layouts/profile/ProfileFormLayout';
import Lock from 'mdi-vue/Lock'

import { createNamespacedHelpers } from 'vuex';
import {
    ACTION_WRITER_UPDATE_PASSWORD
} from '@/store/modules/writer/action-types';

const {
    mapActions: mapWriterActions
} = createNamespacedHelpers('writer');

export default {
    components: {
        MainLayout,
        ProfileFormLayout,
        Lock,
        PasswordValidatorHelper
    },
    metaInfo: {
        title: 'Password'
    },
    mixins: [
        validationMixin,
        formValidationMixin,
        passwordValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data() {
        return {
            showPassword: false,
            passwordInFocus: false,
            passwordTestResults: {
                strength: 0,
                error: false,
                message: '',
                color: ''
            },
            password: null,
            showModal: false
        }
    },
    mounted() {
        eventBus.$on('saveSettings', () => {
            this.validate()
        })
    },
    beforeDestroy() {
        eventBus.$off('saveSettings')
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapWriterActions({
            updatePassword: ACTION_WRITER_UPDATE_PASSWORD
        }),
        confirm() {
            try {
                const response = this.updatePassword({
                    password: this.password
                })
                if (response) {
                    eventBus.$emit('showSnackBar', 'Your password has been changed.', 'success');
                    this.$router.push({ name: 'profile' })
                }
            } catch (err) {
                eventBus.$emit('showSnackBar', 'Failed to update email.', 'error');
            }
        },
        submit() {
            this.confirm()
            // this.showModal = true
        }
    }
}
</script>

<style lang="scss" scoped>
.profile .profile-info__column {
    flex-direction: column !important;
}
.profile-info__form-row {
    .profile-info__col50 {
        margin-top: 0 !important;
    }
}
@media screen and (max-width: 768px){
    .profile-info__form-row {
        .profile-info__col50:nth-child(2) {
            margin-top: 24px !important;
        }
    }
}

.progress-linear{
    margin-top: 10px;
}
</style>
